import Vue from 'vue';
import Router from 'vue-router';
import { Page404 } from '@cloudmanufacturingtechnologies/portal-components';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "Home" */ './pages/index/PageIndex.vue'),
    },
    {
      path: '/homologation',
      name: 'Homologations',
      component: () => import(/* webpackChunkName: "Homologations" */ './pages/homologations/PageHomologations.vue'),
    },
    {
      path: '/brand',
      name: 'Brands',
      component: () => import(/* webpackChunkName: "Brands" */ './pages/brands/PageBrands.vue'),
    },
    {
      path: '/brand/add',
      name: 'BrandAdd',
      component: () => import(/* webpackChunkName: "BrandAdd" */ './pages/brandAdd/PageBrandAdd.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Brands',
            link: '#/brand',
          },
          {
            name: 'BrandAdd',
          },
        ],
      },
    },
    {
      path: '/brand/:brandUUID',
      name: 'Brand',
      component: () => import(/* webpackChunkName: "Brand" */ './pages/brand/PageBrand.vue'),
      props: true,
      children: [
        {
          path: 'general',
          name: 'BrandGeneral',
          component: () => import(/* webpackChunkName: "BrandGeneral" */ './pages/brand/general/PageBrandGeneral.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'subscription/billing',
          name: 'BrandSubscriptionAndBilling',
          component: () => import(/* webpackChunkName: "BrandSubscriptionAndBilling" */ './pages/brand/subscriptionAndBilling/PageBrandSubscriptionAndBilling.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'security',
          name: 'BrandUserAndSecurity',
          component: () => import(/* webpackChunkName: "BrandUserAndSecurity" */ './pages/brand/usersAndSecurity/PageBrandUserAndSecurity.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'features',
          name: 'BrandFeatures',
          component: () => import(/* webpackChunkName: "BrandFeatures" */ './pages/brand/features/PageBrandFeatures.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'parts',
          name: 'BrandParts',
          component: () => import(/* webpackChunkName: "BrandParts" */ './pages/brand/parts/PageBrandParts.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'brandQuotes',
          name: 'BrandQuotes',
          component: () => import(/* webpackChunkName: "BrandParts" */ './pages/brand/brandQuotes/PageBrandQuotes.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'brandOrders',
          name: 'BrandOrders',
          component: () => import(/* webpackChunkName: "BrandParts" */ './pages/brand/brandOrders/PageBrandOrders.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'notifications',
          name: 'BrandNotifications',
          component: () => import(/* webpackChunkName: "BrandNotifications" */ './pages/brand/notifications/PageBrandNotifications.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'homologationRules',
          name: 'BrandHomologationRules',
          component: () => import(/* webpackChunkName: "BrandNotifications" */ './pages/brand/homologationRules/PageBrandHomologationRules.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
        {
          path: 'external/services',
          name: 'BrandExternalServices',
          component: () => import(/* webpackChunkName: "BrandExternalServices" */ './pages/brand/externalServices/PageBrandExternalServices.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Brands',
                link: '#/brand',
              },
              {
                name: 'Brand',
              },
            ],
          },
        },
      ],
      meta: {
        breadcrumb: [
          {
            name: 'Brands',
            link: '#/brand',
          },
          {
            name: 'Brand',
          },
        ],
      },
    },
    {
      path: '/brand/:brandUUID/part/:partUUID',
      name: 'Part',
      component: () => import(/* webpackChunkName: "Part" */ './pages/part/PagePart.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Brand',
            link: '#/brand/:brandUUID/parts',
          },
          {
            name: 'Part',
          },
        ],
        parent: ['PartHomologation', 'Order', 'Quote'],
      },
    },
    {
      path: '/homologation/brand/:brandUUID/part/:partUUID',
      name: 'PartHomologation',
      component: () => import(/* webpackChunkName: "PartHomologation" */ './pages/homologation/PageHomologation.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Homologations',
            link: '#/homologation',
          },
          {
            name: 'PartHomologation',
          },
        ],
      },
    },
    {
      path: '/parts',
      name: 'Parts',
      component: () => import(/* webpackChunkName: "Part" */ './pages/parts/PageParts.vue'),
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import(/* webpackChunkName: "Logout" */ './pages/logout/PageLogout.vue'),
    },
    {
      path: '/order',
      name: 'Orders',
      component: () => import(/* webpackChunkName: "Orders" */ './pages/orders/PageOrders.vue'),
    },
    {
      path: '/order/:orderUUID',
      name: 'Order',
      component: () => import(/* webpackChunkName: "Order" */ './pages/order/PageOrder.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Orders',
            link: '#/order',
          },
          {
            name: 'Order',
          },
        ],
        parent: ['Quote'],
      },
    },
    {
      path: '/quote',
      name: 'Quotes',
      component: () => import(/* webpackChunkName: "Quotes" */ './pages/quotes/PageQuotes.vue'),
    },
    {
      path: '/quote/:quoteUUID',
      name: 'Quote',
      component: () => import(/* webpackChunkName: "Quote" */ './pages/quote/PageQuote.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Quotes',
            link: '#/quote',
          },
          {
            name: 'Quote',
          },
        ],
      },
    },
    {
      path: '/supplier',
      name: 'Suppliers',
      component: () => import(/* webpackChunkName: "Suppliers" */ './pages/suppliers/PageSuppliers.vue'),
    },
    {
      path: '/supplier/add',
      name: 'SupplierAdd',
      component: () => import(/* webpackChunkName: "SupplierAdd" */ './pages/supplierAdd/PageSupplierAdd.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Suppliers',
            link: '#/supplier',
          },
          {
            name: 'SupplierAdd',
          },
        ],
      },
    },
    {
      path: '/supplier/:supplierUUID',
      name: 'Supplier',
      component: () => import(/* webpackChunkName: "Supplier" */ './pages/supplier/PageSupplier.vue'),
      props: true,
      children: [
        {
          path: 'general',
          name: 'SupplierGeneral',
          component: () => import(/* webpackChunkName: "SupplierGeneral" */ './pages/supplier/general/PageSupplierGeneral.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Suppliers',
                link: '#/supplier',
              },
              {
                name: 'Supplier',
              },
            ],
          },
        },
        {
          path: 'security',
          name: 'SupplierUserAndSecurity',
          component: () => import(/* webpackChunkName: "SupplierUserAndSecurity" */ './pages/supplier/userAndSecurity/PageSupplierUserAndSecurity.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Suppliers',
                link: '#/supplier',
              },
              {
                name: 'Supplier',
              },
            ],
          },
        },
        {
          path: 'technologies',
          name: 'SupplierTechnologies',
          component: () => import(/* webpackChunkName: "SupplierTechnologies" */ './pages/supplier/technologies/PageSupplierTechnologies.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Suppliers',
                link: '#/supplier',
              },
              {
                name: 'Supplier',
              },
            ],
          },
        },
        {
          path: 'printers',
          name: 'SupplierPrinters',
          component: () => import(/* webpackChunkName: "SupplierPrinters" */ './pages/supplier/printers/PageSupplierPrinters.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Suppliers',
                link: '#/supplier',
              },
              {
                name: 'Supplier',
              },
            ],
          },
        },
        {
          path: 'external/services',
          name: 'SupplierExternalServices',
          component: () => import(/* webpackChunkName: "SupplierExternalServices" */ './pages/supplier/externalServices/PageSupplierExternalServices.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Suppliers',
                link: '#/supplier',
              },
              {
                name: 'Supplier',
              },
            ],
          },
        },
        {
          path: 'featuresanddelivery',
          name: 'SupplierFeaturesAndDelivery',
          component: () => import(/* webpackChunkName: "SupplierFeaturesAndDelivery" */ './pages/supplier/featuresAndDelivery/PageSupplierFeaturesAndDelivery.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Suppliers',
                link: '#/supplier',
              },
              {
                name: 'Supplier',
              },
            ],
          },
        },
        {
          path: 'privatewarehouses',
          name: 'SupplierPrivateWarehouses',
          component: () => import(/* webpackChunkName: "SupplierPrivateWarehouses" */ './pages/supplier/privateWarehousesManagement/PageSupplierPrivateWarehousesManagement.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Suppliers',
                link: '#/supplier',
              },
              {
                name: 'Supplier',
              },
            ],
          },
        },
        {
          path: 'whiteLabelConsumption',
          name: 'SupplierWhiteLabelConsumption',
          component: () => import(/* webpackChunkName: "SupplierWhiteLabelConsumption" */ './pages/supplier/whiteLabelConsumption/PageSupplierWhiteLabelConsumption.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Suppliers',
                link: '#/supplier',
              },
              {
                name: 'Supplier',
              },
            ],
          },
        },
      ],
    },
    {
      path: '/users',
      name: 'Users',
      component: () => import(/* webpackChunkName: "Users" */ './pages/users/PageUsers.vue'),
    },
    {
      path: '/user/account',
      name: 'UserAccount',
      component: () => import(/* webpackChunkName: "UserAccount" */ './pages/userAccount/PageUserAccount.vue'),
    },
    {
      path: '/users/add',
      name: 'UserAdd',
      component: () => import(/* webpackChunkName: "UserAdd" */ './pages/userAdd/PageUserAdd.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Users',
            link: '#/users',
          },
          {
            name: 'UserAdd',
          },
        ],
      },
    },
    {
      path: '/users/:userUUID',
      name: 'User',
      component: () => import(/* webpackChunkName: "User" */ './pages/user/PageUser.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Users',
            link: '#/users',
          },
          {
            name: 'User',
          },
        ],
        parent: ['Supplier', 'Brand'],
      },
    },
    {
      path: '/users/:userUUID/history',
      name: 'UserLoginHistory',
      component: () => import(/* webpackChunkName: "UserLoginHistory" */ './pages/userLoginHistory/PageUserLoginHistory.vue'),
      meta: {
        breadcrumb: [
          {
            name: 'Users',
            link: '#/users',
          },
          {
            name: 'User',
            link: '#/users/:userUUID',
          },
          {
            name: 'UserLoginHistory',
          },
        ],
      },
    },
    {
      path: '/discounts',
      name: 'Discounts',
      component: () => import(/* webpackChunkName: "Discounts" */ './pages/discounts/PageDiscounts.vue'),
    },
    {
      path: '/offers',
      name: 'Offers',
      component: () => import(/* webpackChunkName: "Offers" */ './pages/offers/PageOffers.vue'),
    },
    {
      path: '/themes',
      name: 'Themes',
      component: () => import(/* webpackChunkName: "Themes" */ './pages/themes/PageThemes.vue'),
    },
    {
      path: '/price/estimation/',
      name: 'PriceEstimation',
      component: () => import(/* webpackChunkName: "PriceEstimation" */ './pages/priceEstimation/PagePriceEstimation.vue'),
    },
    {
      path: '/services',
      name: 'Services',
      component: () => import(/* webpackChunkName: "Services" */ './pages/services/PageServices.vue'),
    },
    {
      path: '/system',
      name: 'System',
      component: () => import(/* webpackChunkName: "System" */ './pages/system/PageSystem.vue'),
      props: true,
      children: [
        {
          path: 'monitoring',
          name: 'SystemMonitoring',
          component: () => import(/* webpackChunkName: "SystemMonitoring" */ './pages/system/monitoring/PageSystemMonitoring.vue'),
        },
        {
          path: 'backups',
          name: 'SystemBackups',
          component: () => import(/* webpackChunkName: "SystemBackups" */ './pages/system/backups/PageSystemBackups.vue'),
        },
        {
          path: 'adminUsers',
          name: 'SystemAdminUsers',
          component: () => import(/* webpackChunkName: "SystemAdminUsers" */ './pages/system/adminUsers/PageSystemAdminUsers.vue'),
        },
        {
          path: 'documentConfigurations',
          name: 'SystemDocumentConfigurations',
          component: () => import(/* webpackChunkName: "SystemDocumentConfigurations" */ './pages/system/documentConfigurations/PageSystemDocumentConfigurations.vue'),
        },
      ]
    },
    {
      path: '/imageUpdate',
      name: 'ImageUpdate',
      component: () => import(/* webpackChunkName: "ImageUpdate" */ './pages/imageUpdate/PageImageUpdate.vue'),
    },
    {
      path: '*',
      name: '404',
      component: Page404,
    },
  ],
});
