<template>
  <v-app id="app">
    <UpdateApp></UpdateApp>
    <OfflineApp></OfflineApp>
    <BeelseDetectBrowser ref="MyBeelseDetectBrowser"></BeelseDetectBrowser>
    <BeelseNotifications ref="BCMNotification"></BeelseNotifications>
    <portal-skeleton v-if="loaded && componentToLoad === 'portalSkeleton'">
      <template v-slot:menu-header="{ miniVariant }">
        <div style="height: 120px">
          <router-link
            :to="{name: 'Home'}"
          >
            <template v-if="miniVariant">
              <v-img
                lazy-src="./assets/logo_cut.png"
                height="85"
                width="300"
                contain
                src="./assets/logo_cut.png"
                alt="logo"
              ></v-img>
            </template>
            <template v-else>
              <v-img
                lazy-src="./assets/logo.png"
                max-height="85"
                max-width="300"
                contain
                src="./assets/logo.png"
                alt="logo"
              ></v-img>
            </template>
          </router-link>
          <v-list-item-subtitle v-if="!miniVariant" class="mt-3 text-center">
            <h2 class="white--text">
              {{ $t('AdminPortal') }}
            </h2>
          </v-list-item-subtitle>
        </div>
      </template>
      <template v-slot:menu-body>
        <MenuBCM
          :divided-menus-items="menuItems"
          :divider-color="'blue-grey darken-2'"
        ></MenuBCM>
      </template>
      <template v-slot:menu-footer>
      </template>
      <template v-slot:top-bar>
        <v-spacer></v-spacer>
        <v-divider vertical class="mx-0"></v-divider>
        <DownloadProgressBtn></DownloadProgressBtn>
        <v-divider vertical class="mx-0"></v-divider>
        <i18n-selector></i18n-selector>
        <v-divider vertical class="mx-0"></v-divider>
        <UserMenu :user="$appConfig.user" :user-menu-items="userMenuItems"></UserMenu>
      </template>
      <template v-slot:default>
        <UserIdentitySetter></UserIdentitySetter>
        <Breadcrumbs></Breadcrumbs>
        <router-view></router-view>
      </template>
    </portal-skeleton>
    <Login
      v-else-if="loaded && componentToLoad === 'login'"
      :global-error-message="globalLoginErrorMessage"
      @login="login"
      @forgotPassword="forgotPassword"
    >
      {{ $t('Admin') }}
    </Login>
    <template v-else>
      <v-container
        fill-height
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col v-if="loaded" align="center">
            <v-row>
              <v-col>
                <v-icon
                  color="error"
                  size="100"
                >
                  fas fa-exclamation-circle
                </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>{{ $t('Error') }}</h2>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else align="center">
            <div class="img-background-app"></div>
            <v-row>
              <v-col>
                <SpinnerBeelse
                  :multiple="true"
                  color="orange"
                  :size="200"
                  logo="B"
                  :display-logo="false"
                >
                </SpinnerBeelse>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="text-h4 mt-12">
                  {{ $t('Loading') }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-app>
</template>

<script>
import Vue from 'vue';

import {
  ApiErrorParser,
  BeelseNotifications,
  BeelseDetectBrowser,
  DownloadProgressBtn,
  EventBus,
  I18nSelector,
  Login,
  LoginInformationTracker,
  MenuBCM,
  OfflineApp,
  PortalSkeleton,
  SpinnerBeelse,
  UpdateApp,
  UserIdentitySetter,
  UserMenu
} from '@cloudmanufacturingtechnologies/portal-components';

import Breadcrumbs from './components/breabcrumbs/Breadcrumbs';


const i18nData = require('./app.i18n');

Vue.prototype.$appConfig = {
  user: {},
  userAdminRoles: [],
  menuItems: [],
  userMenuItems: [],
  qboURL: null,
  stripeURL: null,
};

export default {
  name: 'App',
  components: {
    BeelseNotifications,
    BeelseDetectBrowser,
    Breadcrumbs,
    DownloadProgressBtn,
    I18nSelector,
    Login,
    MenuBCM,
    OfflineApp,
    PortalSkeleton,
    SpinnerBeelse,
    UpdateApp,
    UserIdentitySetter,
    UserMenu
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      userMenuItems: null,
      menuItems: null,
      user: this.$appConfig.user,
      loaded: false,
      componentToLoad: '',
      globalLoginErrorMessage: '',
    };
  },
  created() {
    this.$router.onReady(()=>{
      this.init();
    });

    this.$router.onError(()=>{
      this.loaded = true;
    });

    EventBus.$on('globalLoginProcessComplete', () => {
      this.checkLogin();
    });

    /**
     * Refresh the application if not loaded after 5sec
     * The goal is to avoid blank screen (now with spinner) on ServiceWorker post update
     */
    setTimeout(
      function() {
        if(!this.componentToLoad || this.componentToLoad.trim() === '') {
          navigator.serviceWorker.getRegistrations()
            .then((registrations) => {
              for(const registration of registrations) {
                registration.unregister();
              }
              document.location.reload();
            })
            .finally(()=>{
              this.loaded = true;
            });
        }
      }.bind(this),
      5000
    );
  },
  mounted() {
    Vue.prototype.$notification = this.$refs.BCMNotification;
  },
  methods: {
    init() {
      this.$vuetify.lang.current = this.$userLocale;
      if (process.env.NODE_ENV === 'development') {
        this.$apiInstance.apiClient.basePath = process.env.VUE_APP_API_URL;
        this.$appConfig.qboURL = 'https://app.sandbox.qbo.intuit.com/app';
        this.$appConfig.stripeURL = 'https://dashboard.stripe.com/test';
        this.checkLogin();
      } else if (process.env.NODE_ENV === 'production') {
        this.$appConfig.qboURL = 'https://app.qbo.intuit.com/app';
        this.$appConfig.stripeURL = 'https://dashboard.stripe.com';
        this.$http.get('env_var').then(
          (response) => {
            this.$apiInstance.apiClient.basePath = response.data.WS_URL;
            if (
              /^http:\/\/172.\d+.\d+.\d+\/.*$/.test(
                this.$apiInstance.apiClient.basePath
              ) ||
              /^https:\/\/.*\.test\..*\/.*$/.test(
                this.$apiInstance.apiClient.basePath
              )
            ) {
              this.$appConfig.qboURL = 'https://app.sandbox.qbo.intuit.com/app';
            }
            this.checkLogin();
          },
          (error) => {
            this.globalLoginErrorMessage = `${this.$t('BadConfig')} - status: ${
              error.status
            }`;
          }
        );
      }
    },
    deleteCookies() {
      this.$deleteCookie('_pu');
      this.$deleteCookie('_pp');
    },
    loadLogin(errorMessage = '') {
      /**
       * Delete Cookies and load login component
       */
      this.deleteCookies();
      this.globalLoginErrorMessage = errorMessage;
      this.componentToLoad = 'login';
      this.loaded = true;
    },
    login(email, password) {
      this.globalLoginErrorMessage = '';
      const authBody = new this.$BcmModel.AuthBody(email, password);
      this.$apiInstance.auth(authBody).then(
        (data) => {
          const date = new Date();
          date.setHours(date.getHours() + 8);
          const dateString = date.toGMTString();
          this.$setCookie('_pu', data._id, dateString);
          this.$setCookie('_pp', data.token, dateString);
          /**
           * Login Success
           */
          this.checkLogin();
        },
        (error) => {
          if(error?.error?.message?.includes('Request has been terminated')) {
            this.loadLogin(this.$t('loginFailedTemporary'));
          }else{
            this.loadLogin(ApiErrorParser.parseAuth(error));
          }
        }
      );
    },
    forgotPassword(email) {
      this.globalLoginErrorMessage = '';
      const emailRequestBody = new this.$BcmModel.SendNewUserPasswordBody(email);
      this.$apiInstance
        .sendNewUserPassword(emailRequestBody)
        .then(() => {
          /**
           * Forgot password Success
           */
        })
        .catch((err) => {
          this.loadLogin(ApiErrorParser.parseAuth(error));
        });
    },
    checkLogin() {
      const userUUID = this.$getCookie('_pu');
      const userPassword = this.$getCookie('_pp');

      if (!userUUID || !userPassword) {
        this.loadLogin();
      } else {
        /**
         * Cookies found, verify Authentication
         */
        this.$apiInstance.apiClient.authentications[
          'JWTAuth'
        ].accessToken = userPassword;
        this.$apiInstance.getUser(userUUID).then(
          (data) => {
            this.$appConfig.user = data;
            this.getAdminRoles();
            this.getBackendTranslations();
          },
          (error) => {
            this.loadLogin(ApiErrorParser.parseAuth(error));
          }
        );
      }
    },
    getAdminRoles() {
      this.$apiInstance.getAdminRoles(this.$appConfig.user._id).then(
        (data) => {
          this.$appConfig.userAdminRoles = data;
          /**
           * Check and add login global error message
           * if the user doesn't have access to this portal
           */
          if (this.$appConfig.userAdminRoles.length === 0) {
            this.loadLogin(this.$t('NoAccess'));
          } else {
            this.menuItems = [
              [
                {
                  name: this.$t('Users'),
                  iconClass: 'fas fa-users',
                  fullPath: {name: 'Users'},
                },
              ],
              [
                {
                  name: this.$t('Brands'),
                  iconClass: 'fas fa-warehouse',
                  fullPath: {name: 'Brands'},
                },
                {
                  name: this.$t('Suppliers'),
                  iconClass: 'fas fa-city',
                  fullPath: {name: 'Suppliers'},
                },
              ],
              [
                {
                  name: this.$t('BrandParts'),
                  iconClass: 'fas fa-cubes',
                  fullPath: {name: 'Parts'},
                },
              ],
              [
                {
                  name: this.$t('Homologations'),
                  iconClass: 'fas fa-check-double',
                  fullPath: {name: 'Homologations'},
                },
                {
                  name: this.$t('Quotes'),
                  iconClass: 'fas fa-file-invoice',
                  fullPath: {name: 'Quotes'},
                },
                {
                  name: this.$t('Orders'),
                  iconClass: 'fa fa-truck',
                  fullPath: {name: 'Orders'},
                },
              ],
              [
                {
                  name: this.$t('Offers'),
                  iconClass: 'fas fa-file-signature',
                  fullPath: {name: 'Offers'},
                },
                {
                  name: this.$t('Discounts'),
                  iconClass: 'fas fa-percent',
                  fullPath: {name: 'Discounts'},
                },
                {
                  name: this.$t('Themes'),
                  iconClass: 'fas fa-paint-roller',
                  fullPath: {name: 'Themes'},
                },
                {
                  name: this.$t('PriceEstimation'),
                  iconClass: 'fas fa-euro-sign',
                  fullPath: {name: 'PriceEstimation'},
                },
              ],
              [
                {
                  name: this.$t('ExternalServices'),
                  iconClass: 'fas fa-external-link-alt',
                  fullPath: {name: 'Services'},
                },
                {
                  name: this.$t('System'),
                  iconClass: 'fas fa-network-wired',
                  fullPath: {name: 'System'},
                },
              ]
            ];

            this.userMenuItems = [
              {
                name: this.$t('UserMenuMyAccount'),
                icon: 'fas fa-cogs',
                route: {name: 'UserAccount'},
              },
              {
                name: this.$t('UserMenuLogOut'),
                icon: 'fas fa-sign-out-alt',
                route: {name: 'Logout'},
              },
            ];
            /**
             * Load Menu
             */
            this.componentToLoad = 'portalSkeleton';
            this.loaded = true;
            LoginInformationTracker.push(
              this.$apiInstance,
              this.$BcmModel,
              this.$appConfig.user,
              'admin'
            );
          }
        },
        (error) => {
          this.loadLogin('NoAccess');
        }
      );
    },
    getBackendTranslations() {
      this.$apiInstance.getAllTechnologiesAndMaterialsCompatibilities().then(data => {
        for(const technologyObj of data) {
          for(const materialObj of technologyObj.materials) {
            for(const language of Object.keys(materialObj.name)) {
              const material = materialObj.material;
              const translation = {};
              translation[material] = materialObj.name[language];
              this.$root.$options.i18n.mergeLocaleMessage(language, translation);
            }
          }
        }
      });
    }
  },
};
</script>