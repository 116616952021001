var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "app"
    }
  }, [_c('UpdateApp'), _c('OfflineApp'), _c('BeelseDetectBrowser', {
    ref: "MyBeelseDetectBrowser"
  }), _c('BeelseNotifications', {
    ref: "BCMNotification"
  }), _vm.loaded && _vm.componentToLoad === 'portalSkeleton' ? _c('portal-skeleton', {
    scopedSlots: _vm._u([{
      key: "menu-header",
      fn: function (_ref) {
        var miniVariant = _ref.miniVariant;
        return [_c('div', {
          staticStyle: {
            "height": "120px"
          }
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: 'Home'
            }
          }
        }, [miniVariant ? [_c('v-img', {
          attrs: {
            "lazy-src": require("./assets/logo_cut.png"),
            "height": "85",
            "width": "300",
            "contain": "",
            "src": require("./assets/logo_cut.png"),
            "alt": "logo"
          }
        })] : [_c('v-img', {
          attrs: {
            "lazy-src": require("./assets/logo.png"),
            "max-height": "85",
            "max-width": "300",
            "contain": "",
            "src": require("./assets/logo.png"),
            "alt": "logo"
          }
        })]], 2), !miniVariant ? _c('v-list-item-subtitle', {
          staticClass: "mt-3 text-center"
        }, [_c('h2', {
          staticClass: "white--text"
        }, [_vm._v(" " + _vm._s(_vm.$t('AdminPortal')) + " ")])]) : _vm._e()], 1)];
      }
    }, {
      key: "menu-body",
      fn: function () {
        return [_c('MenuBCM', {
          attrs: {
            "divided-menus-items": _vm.menuItems,
            "divider-color": 'blue-grey darken-2'
          }
        })];
      },
      proxy: true
    }, {
      key: "menu-footer",
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: "top-bar",
      fn: function () {
        return [_c('v-spacer'), _c('v-divider', {
          staticClass: "mx-0",
          attrs: {
            "vertical": ""
          }
        }), _c('DownloadProgressBtn'), _c('v-divider', {
          staticClass: "mx-0",
          attrs: {
            "vertical": ""
          }
        }), _c('i18n-selector'), _c('v-divider', {
          staticClass: "mx-0",
          attrs: {
            "vertical": ""
          }
        }), _c('UserMenu', {
          attrs: {
            "user": _vm.$appConfig.user,
            "user-menu-items": _vm.userMenuItems
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('UserIdentitySetter'), _c('Breadcrumbs'), _c('router-view')];
      },
      proxy: true
    }], null, false, 1641643277)
  }) : _vm.loaded && _vm.componentToLoad === 'login' ? _c('Login', {
    attrs: {
      "global-error-message": _vm.globalLoginErrorMessage
    },
    on: {
      "login": _vm.login,
      "forgotPassword": _vm.forgotPassword
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Admin')) + " ")]) : [_c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.loaded ? _c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-icon', {
    attrs: {
      "color": "error",
      "size": "100"
    }
  }, [_vm._v(" fas fa-exclamation-circle ")])], 1)], 1), _c('v-row', [_c('v-col', [_c('h2', [_vm._v(_vm._s(_vm.$t('Error')))])])], 1)], 1) : _c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "img-background-app"
  }), _c('v-row', [_c('v-col', [_c('SpinnerBeelse', {
    attrs: {
      "multiple": true,
      "color": "orange",
      "size": 200,
      "logo": "B",
      "display-logo": false
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('p', {
    staticClass: "text-h4 mt-12"
  }, [_vm._v(" " + _vm._s(_vm.$t('Loading')) + " ")])])], 1)], 1)], 1)], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }