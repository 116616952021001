<template lang="html" src="./breadcrumbs.template.vue"></template>

<style lang="scss" src="./breadcrumbs.scss"></style>

<script>
import { EventBus } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./breadcrumbs.i18n');
export default {
  name: 'Breadcrumbs',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  created() {
    EventBus.$on('RouteUpdated', this.getBreadcrumbs);
  },
  methods: {
    goTo(payload) {
      this.$router.push(payload);
    },
    getBreadcrumbs() {
      this.breadcrumbs = [];
      if (this.$route.meta.breadcrumb) {
        this.$route.meta.breadcrumb.forEach((route) => {
          route.configuredLink = null;
          if (route.link) {
            route.configuredLink = route.link;
            route.configuredLink = route.configuredLink.replace(
              ':brandUUID',
              this.$route.params.brandUUID
                ? this.$route.params.brandUUID
                : this.$appConfig.currentBrand
            );
            route.configuredLink = route.configuredLink.replace(
              ':userUUID',
              this.$route.params.userUUID
                ? this.$route.params.userUUID
                : this.$appConfig.user
            );
            route.configuredLink = route.configuredLink.replace(
              ':partUUID',
              this.$route.params.partUUID
            );
          }
          let docNumber = null;
          if (route.name === 'Quote') {
            this.$apiInstance
              .getAdminQuote(this.$route.params.quoteUUID)
              .then((data) => {
                docNumber = data.quoteNumber;
                this.breadcrumbs.push({
                  text: `n°${docNumber}`,
                  disable: !!route.configuredLink,
                  href: route.configuredLink,
                });
              });
          } else if (route.name === 'Order') {
            this.$apiInstance
              .getAdminOrder(this.$route.params.orderUUID)
              .then((data) => {
                docNumber = data.invoiceNumber;
                this.breadcrumbs.push({
                  text: `n°${docNumber}`,
                  disable: !!route.configuredLink,
                  href: route.configuredLink,
                });
              });
          } else {
            this.breadcrumbs.push({
              text: this.$t(route.name),
              disable: !!route.configuredLink,
              href: route.configuredLink,
            });
          }
        });
      }
    },
  },
};
</script>
