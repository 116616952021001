var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-breadcrumbs"
  }, [_vm.breadcrumbs.length > 0 ? _c('v-breadcrumbs', {
    staticClass: "orange--text pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          staticClass: "px-0",
          attrs: {
            "color": "orange"
          }
        }, [_vm._v(" fas fa-chevron-right ")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          staticClass: "text-none pa-0",
          staticStyle: {
            "min-width": "fit-content !important"
          },
          attrs: {
            "text": "",
            "ripple": false,
            "color": "orange",
            "disabled": !item.disable,
            "href": item.href
          }
        }, [_vm._v(" " + _vm._s(item.text) + " ")])];
      }
    }], null, false, 1135484842)
  }) : _c('div', {
    staticClass: "mb-6"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }